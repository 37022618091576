/* Theme Name: Vestonic - Landing page Template
   Author: Themesdesign
   Version: 1.0.0
   Created: Dec 2016
   File Description:Main CSS File
*/


/*---------------------

Table of contents

1. Loader
2. Helper classes 
3. Buttons
4. Navbar
5. Home
6. Services
7. Features
8. Testimonials / Clients
9. Team
10. CTA Section
11. Pricing
12. Fun facts
13. Contact
14. Footer
15. Animation - Magnific popoup
16. Responsive

---------------------*/


body {
  font-family: 'Open Sans', sans-serif;
  color: #585858;
  font-size: 14px;
  overflow-x: hidden;
  position: relative;
}

a {
  text-decoration: none !important;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 600;
}


h1{
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}



/* === 1.Loader === */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9999999;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.spinner {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(56, 83, 233, 0.2);
  border-right: 5px solid rgba(56, 83, 233, 0.2);
  border-bottom: 5px solid rgba(56, 83, 233, 0.2);
  border-left: 5px solid #ef1923;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}





/* ==== 2. Helper classes ==== */

.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.title-box {
  padding-bottom: 30px;
}

.title-box i{
  font-size: 36px;
  color: #aaa;
}

.title-box p {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 10px;
}

.title-box h2 {
  font-weight: 700;
  margin-top: 10px;
}

.title-box h4 {
  width: 60%;
  line-height: 24px;
  margin: 10px auto;
  font-size: 16px;
}

.bg-lightgray {
  background-color: #fafafa;
}

.bg-dark {
  background-color: #222328;
}

.text-custom {
  color: #ef1923;
}

.text-warning {
  color: #ffbd4a;
}

.margin-t-20 {
  margin-top: 20px;
}

.margin-t-30 {
  margin-top: 30px;
}

.margin-t-50 {
  margin-top: 50px;
}

.font-2 {
  font-style: italic;
  font-weight: 400;
  font-family: 'Crete Round', serif;
}

.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}


/* Background images css */
.bg-home {
  background: url("../images/001.png");
  background-size: cover;
  background-color: #222328;
  position: relative;
}

.bg-pattern {
  background: url("../images/bg-pattern.png");
}


/* Social */
.social li a {
  display: block;
  height: 36px;
  width: 36px;
  color: #222328 !important;
  line-height: 36px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 50%;
  border: 2px solid #585858;;
}

.social li a:hover {
  color: #ffffff !important;
  border-color: transparent;
}

/* ==== 3. Buttons ==== */

.btn {
  color: #ffffff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  padding: 0.4rem 1em;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-out;
     -moz-transition: all 0.4s ease-out;
       -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
}

.btn:hover {
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
}

.btn-sm {
  padding: 7px 24px;
  font-size: 12px;
}

.btn-custom {
  background-color: #ef1923;
}

.btn-custom:hover {
  background-color: #451f1f;
}

.btn-link {
  color: #ef1923 !important;
  box-shadow: none !important;
  padding: 0;
}

.bg-facebook:hover {
  background-color: #3b5998 !important;
}

.bg-twitter:hover {
  background-color: #00aced !important;
}

.bg-linkedin:hover {
  background-color: #007bb6 !important;
}

.bg-dribbble:hover {
  background-color: #ea4c89 !important;
}

.bg-googleplus:hover {
  background-color: #dd4b39 !important;
}




/*===== 4. Navbar Custom ======*/

.navbar-custom{
  padding: 15px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
       -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
}

.sticky-wrapper {
  position: absolute;
  width: 100%;
}

.logo {
  font-weight: 500;
  font-size: 26px;
  color: #585858 !important;
}

.logo span {
  color: #ef1923;
}

.navbar-custom .navbar-nav li a {
  color: #272727;
  font-size: 13px;
  background-color: transparent !important;
  padding: 7px 10px;
  margin: 0 5px;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border-radius: 3px;
}

.navbar-custom .navbar-nav li a:hover,.navbar-custom .navbar-nav li a:focus,.navbar-custom .navbar-nav li a:active {
  color: rgb(0, 0, 0);
}

.navbar-custom .navbar-nav li a.active{
  color: #ffffff !important;
  background-color: #ef1923 !important;
}

.is-sticky .navbar-custom{
  margin-top: 0px;
  padding: 10px 0px;
  background-color: #ffffff;
    -moz-box-shadow: 0 10px 33px rgba(0,0,0,.1);
  -webkit-box-shadow: 0 10px 33px rgba(0,0,0,.1);
  box-shadow: 0 10px 33px rgba(0,0,0,.1);
}

.navbar-toggle {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.navbar-custom .btn-custom {
  margin-top: 8px;
  margin-left: 20px;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: transparent;
  border-color: #337ab7;
}


/* Navbar white */

.navbar-header-white {
  background-color: #ffffff !important;
  padding: 15px 0px !important;
}

.navbar-header-white .logo {
  color: #222328 !important;
}


.navbar-header-white.navbar-custom .navbar-toggle {
  color: #222328;
}

/*===== 5.Home ======*/

.home-fullscreen {
  height: 100%;
  min-height: 400px;
}

.home-wrapper-alt{
  display: table-cell;
  vertical-align: middle;
}

.home-wrapper h1{
  line-height: 54px;
  margin-top: 0;
  font-weight: bold;
}

.home-wrapper h4 {
  line-height: 26px;
  /* margin-top: 30px; */
  /* margin-bottom: 50px; */
  font-size: 15px;
  font-weight: normal;
}

.video-btn {
  color: #ef0d0d !important;
  letter-spacing: 1px;
  outline: none !important;
  box-shadow: none !important;
}

.video-btn i {
  margin-left: 7px;
  height: 20px;
  width: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #222328;
  line-height: 20px;
  /* border-radius: 50%; */
  /* padding-left: 3px; */
  font-size: 12px;
}

.full-screen {
  display: table;
  height: 100%;
  width: 100%;
}


/* === HOME VARIATIONS === */
.home-small {
  padding: 210px 0 160px 0;
}


/* Home intro */
.home-intro h2 {
  color: #fff;
  font-size: 67px;
  line-height: 61px;
  margin: 0 0 40px;
  font-weight: 600;
}

.home-intro p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding-right: 25%;
}

.home-intro .intro-form {
  background: #fff;
  padding: 0 40px 40px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 8px 15px #dfdede;
}

.home-intro .intro-form input {
  height: 46px;
  margin-bottom: 18px;
  box-shadow: none;
  border: 2px solid #e5e5e5;
  width: 100%;
  padding: 15px 20px;
  color: #000;
  outline: none !important;
}

.home-intro .intro-form input:focus {
  border: 2px solid #999;
}

.home-intro .intro-form h5 {
  color: #fff;
  font-size: 26px;
  font-family: 'Crete Round', serif;
  background: #ef1923;
  padding: 25px 40px 25px;
  margin: 0 -40px 50px;
  font-weight: 500;
}

.home-intro .intro-form h5 span {
  display: block;
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0;
  font-family: Lato;
  opacity: 0.5;
  margin-top: 6px;
}

.home-intro .intro-form p {
  color: #999;
  padding: 17px 30px 0px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin: 0px;
}


/* Home Showcase */
.home-showcase {
  padding: 210px 0px 0px 0px;
}

/* Home Header-2 */
.home-header-2 {
  padding: 240px 0px 160px 0px;
}


/* Home Slider */
.home-slider {
  padding: 250px 0 200px 0;
}
#home-slider {
  position: relative;
  top: 0px;
}

#home-slider .is-prev,#home-slider .is-next  {
  position: absolute;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 20px;
  z-index: 999;
  margin-top: -50px;
  color: #222;
  line-height: 93px;
  text-align: center;
  font-size: 36px;
  cursor: pointer;
  opacity: 0.4;
  transition: .4s;
}

#home-slider .is-next {
  right: 20px;
  left: auto;
}

#home-slider .is-prev:hover,
#home-slider .is-next:hover {
  opacity: 1;
  transition: .4s;
}

#home-slider .flex-direction-nav {
  display: none;
}




/*===== 6.Services ======*/

.services-box {
  padding: 20px 0;
}

.services-box:hover i {
  color: #ef1923;
}

.services-box:hover h4 {
  color: #ef1923;
}

.services-box i {
  font-size: 36px;
  float: left;
  line-height: 60px;
  width: 70px;
  -webkit-transition: all 0.4s ease-out;
     -moz-transition: all 0.4s ease-out;
       -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
}


.services-box p {
  margin-top: 15px;
  line-height: 26px;
  overflow: hidden;
}


/*===== 7.Features ======*/
.features {
  padding: 50px 0;
}

.features-box .number{
  height: 60px;
  width: 60px;
  background-color: #ef1923;
  color: #ffffff;
  border: 2px solid #ef1923;
  font-size: 24px;
  text-align: center;
  line-height: 56px;
  border-radius: 50%;
  font-weight: bolder;
}

.features-box h3 {
  line-height: 30px;
}

.features-box h4 {
  line-height: 24px;
  color: #666;
  margin-bottom: 20px;
}

.features-box p {
  line-height: 28px;
  margin-top: 20px;
  font-size: 16px;
  color: #848e92;
}

.img-shadow {
  box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
}



/* === 8.Testimonials / Clients === */
.testimonial-box h4 {
  line-height: 30px;
  font-family: 'Crete Round', serif;
  font-style: italic;
  font-weight: normal;
}

.testimonial-box .testi-user {
  width: 48px !important;
  height: 48px !important;
  margin: 10px auto;
}

.client-images {
  padding-top: 70px;
}

.client-images img {
  max-height: 50px;
  width: auto !important;
  transition: transform .3s;
  margin: 10px auto;
  opacity: 0.6;
}

.client-images img:hover {
  opacity: 0.8;
  transform: scale(1.15);
}

.owl-controls.clickable {
  display: none !important;
}



/* === 9.Team === */

.team-box {
  text-align: center;
  padding: 30px 20px;
  margin-top: 30px;
}

.team-box img {
  height: 120px;
  margin-bottom: 20px;
}

.team-box p {
  margin: 20px 0;
  line-height: 24px;
}
.team-box h6 {
  font-family: 'Crete Round', serif;
  font-weight: normal;
  font-size: 14px;
}

/* ====== 10.cta-section ======= */
.cta-section h4 {
  line-height: 30px;
  font-family: 'Crete Round', serif;
  font-style: italic;
  font-weight: normal;
}


/* === 11.Pricing === */

.pricing-box {
  text-align: center;
  padding: 30px;
  margin-top: 30px;
  border-radius: 10px;
  border: 2px solid rgba(238, 238, 238, 0.84);
  background-color: #f9f9f9;
}

.pricing-box.active {
    background-color: rgba(245, 99, 99, 0.07);
    border-color: #ef1923;
}

.pricing-box.active .plan-title {
  color: #ef1923;
}

.pricing-header .plan-title{
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}

.pricing-header .plan-price{
  font-size: 42px;
  font-family: 'Crete Round', serif;
}

.plan-features {
  margin-top: 40px;
}

.plan-features li {
  margin: 10px 0;
  line-height: 26px;
}

.plan-features li i {
  color: #0da357;
  margin-right: 5px;
}


/* === 12.Funfacts === */

.funfacts {
  color: #585858;
}

.funfacts .facts i {
  font-size: 36px;
}



/* === 13. Contact === */
.form-control {
  box-shadow: none !important;
  border: 2px solid #84817a;
  height: 44px;
}

.contact-form .form-control {
  margin-bottom: 25px;
}

.parsley-errors-list {
  padding-left: 0;
}
.parsley-errors-list li {
  display: none;
}
.parsley-error {
  border-color: #f00 !important;
}

.error_message {
  padding: 0;
  margin: 0 0 10px;
  color: #f00;
  font-weight: 500;
}

.contact-loader {
  float: left;
}

#success_page {
  text-align: center;
  margin-top: 50px;
}

#success_page h3 {
  color: #0dba26;
}

#success_page p {
  font-family: 'Crete Round', serif;
}

.contact-footer {
  padding-bottom: 70px;
}

.contact-footer-box {
  padding-top: 30px;
}

.contact-footer-box i {
  font-size: 32px;
  margin-bottom: 20px;
  display: block;
}

.contact-footer-box h5 {
  color: #989898;
  font-weight: normal;
}


/* === 14.Footer === */

.footer {
  padding: 70px 0;
}

.footer h5{
  margin-bottom: 15px;
  font-size: 16px;
}

.footer ul {
  margin-bottom: 0;
}

.footer p {
  line-height: 24px;
}

.footer ul.footer-list li a {
  color: #585858;
  display: inline-block;
  padding: 5px 0;
  -webkit-transition: all 0.4s ease-out;
     -moz-transition: all 0.4s ease-out;
       -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
}

.footer ul.footer-list li a:hover  {
  color: rgb(0, 0, 0);
}

.footer .social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-alt .copy-rights{
  margin: 0;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}


/* === 15.Animation === */
/**
 * Fade-zoom animation for Magnific-popup
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out; 
     -moz-transition: all 0.2s ease-in-out; 
       -o-transition: all 0.2s ease-in-out; 
          transition: all 0.2s ease-in-out; 
  -webkit-transform: scale(0.8); 
     -moz-transform: scale(0.8); 
      -ms-transform: scale(0.8); 
       -o-transform: scale(0.8); 
          transform: scale(0.8); 
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1); 
     -moz-transform: scale(1); 
      -ms-transform: scale(1); 
       -o-transform: scale(1); 
          transform: scale(1); 
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8); 
     -moz-transform: scale(0.8); 
      -ms-transform: scale(0.8); 
       -o-transform: scale(0.8); 
          transform: scale(0.8); 
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out; 
     -moz-transition: opacity 0.3s ease-out; 
       -o-transition: opacity 0.3s ease-out; 
          transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.imgin{
  margin-left: auto;
  margin-right: auto;
}


/*======= 16.Responsive ======*/

@media (min-width: 200px) and (max-width: 768px) {

  .navbar-custom {
      background-color: #fff !important;
      padding: 10px 0px;
     }

     .navbar-custom .container{
      width: 90%;
     }
 

}

@media (max-width: 767px) {

  .vertical-content {
    display: inherit !important;
  }

  .navbar-custom{
    margin-top: 0px;
    padding: 5px 0px !important;
    background-color: #ffffff;
    -moz-box-shadow: 0 10px 33px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 33px rgba(0,0,0,.1);
    box-shadow: 0 10px 33px rgba(0,0,0,.1);
  }

  .logo {
    margin-top: 5px;
  }

  .features-box {
    text-align: center;
  }

  .features-box .number {
    margin: 0 auto;
  }

  .footer h5 {
    margin-top: 30px;
  }

  .title-box h4 {
    width: 95%;
    color: red
  }

  .home-intro .intro-form {
    margin-top: 50px;
  }
  
}

@media (max-width: 420px) {

  .home-wrapper h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .facts {
    margin-bottom: 30px;
  }

}

a{
  color: #ef1923;
}